svg.ver-senha,
svg.ver-senha-recuperar,
svg.ver-senha-recuperar2 {
    position: absolute;
    left: 85%;
    fill: var(--cor-principal);
}

svg.ver-senha {
    top: 49%;
}

svg.ver-senha-recuperar {
    top: 47%;
}

svg.ver-senha-recuperar2 {
    top: 66%;
}